<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-17 14:27:31
 * @LastEditTime: 2020-12-24 18:26:52
-->
<template>
  <div class="result h-100">
    <img :src="`${publickPath}static/img/icons/icon-success.png`">
    <div class="title">信息提交成功</div>
    <div class="tip">您可以在个人中心内查看已提交的信息</div>
    <div class="bottom-btn">
      <u-button size="large"
                type="primary"
                @click="toAuto"
                v-if="targetType">{{count}}秒后今日{{targetType==='shop'?'商家入驻':'商品购买登记'}}</u-button>
      <u-button size="large"
                plain
                style="margin-top:15px"
                type="primary"
                @click="toIndex">返回首页</u-button>
    </div>
  </div>
</template>

<script>
import UButton from '@/components/u-button';
export default {
  data () {
    return {
      publickPath: process.env.BASE_URL,
      targetType: this.$route.query.type, //shop 商家入驻 buy 商品购买
      interval: null, // 自动跳转倒计时
      count: 3
    }
  },
  components: {
    UButton
  },
  mounted () {
    this.$nextTick(() => {
      if (this.targetType) {
        this.interval = setInterval(() => {
          this.count--
          if (!(this.count - 1)) {
            clearInterval(this.interval)
            this.toAuto()
          }
        }, 1000)
      }
    })
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  watch: {
  },
  methods: {
    toAuto () {
      if (this.targetType === 'shop') {
        this.$router.push({ path: '/my/shopsCheckIn' })
      } else {
        this.$router.push({ path: '/goods/BuyRegister', query: { id: this.$route.query.id } })
      }
    },
    toIndex () {
      this.$router.push({ path: '/main' })
    }
  },
}
</script>

<style lang="less" scoped>
.result {
  text-align: center;
  position: relative;
  padding: 4.8rem /* 90/18.75 */;
  img {
    height: 6.186667rem /* 116/18.75 */;
  }
  .title {
    margin-top: 1.92rem /* 36/18.75 */;
    font-size: 1.066667rem /* 20/18.75 */;
    font-family: PingFang SC;
    font-weight: 400;
    color: #2e77ee;
  }
  .tip {
    font-size: 0.746667rem /* 14/18.75 */;
    font-family: PingFang SC;
    font-weight: 400;
    color: @fcg;
    margin-top: 0.426667rem /* 8/18.75 */;
  }
  .toAuto {
    position: absolute;
    left: 1.066667rem /* 20/18.75 */;
    bottom: 10.986667rem /* 206/18.75 */;
  }
  .toIndex {
    position: absolute;
    left: 1.066667rem /* 20/18.75 */;
    bottom: 7.786667rem /* 146/18.75 */;
  }
}
</style>